@use "sass:color";

@mixin transparent-background(
  $wallpaper,
  $ui-base-color,
  $ui-highlight-color,
  $no-gap-breakpoint
) {
  .transparent-bg {
    background: url(#{$wallpaper}) no-repeat fixed center top/cover;
  }

  /* 半透明デザイン */

  body,
  .tabs-bar__wrapper {
    background: url($wallpaper) no-repeat fixed center top/cover;
  }

  body.admin,
  .admin-wrapper .sidebar-wrapper__inner {
    background: url($wallpaper) no-repeat fixed center top/cover;
  }

  .drawer__inner {
    background: none;

    .search-results__section {
      background: var(--background-color);
    }

    &__mastodon {
      background: none;
    }
  }

  .compose-form {

    &__highlightable {
      background: rgba(color.adjust($ui-base-color, $lightness: -15%), 0.7);
    }

    .autosuggest-textarea__textarea,
    .spoiler-input__input {
      background: none;
    }
  }

  .explore__search-header {
    background: none;
  }

  .search__input {
    background: rgba(color.adjust($ui-base-color, $lightness: -15%), 0.7);
  }

  @media screen and (max-width: #{ $no-gap-breakpoint - 1 }) {
    body {
      background: none;
      position: relative;
    }

    .layout-single-column .ui__header,
    .admin-wrapper .sidebar__toggle {
      background: none;
      backdrop-filter: var(--background-filter-darker);
    }

    .tabs-bar__wrapper {
      background: none;
      backdrop-filter: var(--background-filter-dark);
    }

    body::before {
      content: "";
      background: url(#{$wallpaper}) no-repeat center center/cover;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
    }
  }
}

/* 変数の追加 */

:root {
  --background-filter-dark: blur(10px) saturate(180%) contrast(75%) brightness(30%);
  --background-filter-darker: blur(10px) saturate(180%) contrast(75%) brightness(15%);
}